import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '../store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
        requiresAuth: false,
      },
    },
    // *===============================================---*
    // *--------- CHAT  ---------------------------------------*
    // *===============================================---*
    {
      path: '/apps/chat',
      name: 'chat',
      component: () => import('@/views/apps/chat/Chat.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'chat-application',
      },
    },
    // *===============================================---*
    // *--------- CALENDAR  ---------------------------------------*
    // *===============================================---*
    {
      path: '/apps/calendar',
      name: 'calendar',
      component: () => import('@/views/apps/calendar/Calendar.vue'),
    },
    // *===============================================---*
    // *--------- USER ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/apps/users/list',
      name: 'config-users-list',
      component: () => import('@/views/apps/user/users-list/UsersList.vue'),
    },
    {
      path: '/apps/users/view/:id',
      name: 'config-users-view',
      component: () => import('@/views/apps/user/users-view/UsersView.vue'),
    },
    {
      path: '/apps/users/edit/:id',
      name: 'config-users-edit',
      component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
      meta: {
        pageTitle: 'Edit',
        breadcrumb: [
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/two-factor-auth',
      name: 'two-factor-auth',
      component: () => import('@/views/TwoFactorAuth.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgotPassword',
      name: 'forgotPassword',
      component: () => import('@/views/Auth/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/changeMyPassword',
      name: 'changeMyPassword',
      component: () => import('@/views/Auth/RecoverPassword.vue'),
      meta: {
        requiresAuth: true,
        layout: 'full',
      },
    },
    {
      path: '/resetPassword',
      name: 'resetPassword',
      component: () => import('@/views/Auth/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    // Invoice
    {
      path: '/apps/invoice/list',
      name: 'apps-invoice-list',
      component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
    },
    {
      path: '/apps/invoice/preview/:id',
      name: 'apps-invoice-preview',
      component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
    },
    {
      path: '/apps/invoice/add/',
      name: 'apps-invoice-add',
      component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
    },
    {
      path: '/apps/invoice/edit/:id',
      name: 'apps-invoice-edit',
      component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/system/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    // {
    //   path: '/faq',
    //   name: 'faq',
    //   component: () => import('@/views/faq/Faq.vue'),
    //   meta: {
    //     requiresAuth: true,
    //     pageTitle: 'FAQ',
    //     breadcrumb: [
    //       {
    //         text: 'Pages',
    //       },
    //       {
    //         text: 'FAQ',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/',
      redirect: 'login',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen1
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

// router.beforeEach((to, from, next) => {
//   const { requiresAuth } = to.meta
//   const isLoggedIn = store.getters['user-auth/isLoggedIn']
//   const requireResetPassword = store.getters['user-auth/requireResetPassword']
//   if (requiresAuth) { // if requiered auth
//     if (!isLoggedIn) next('/login') // if is not logged redirect to login
//     else if (requireResetPassword && to.name !== 'changeMyPassword') next('/changeMyPassword')
//     else next()
//   }
//   next()
// })

export default router
